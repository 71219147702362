/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column name={"informace-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 flex--top" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="--left" anim={""} animS={"5"} style={{"maxWidth":""}}>
            </ColumnWrapper>

            <ColumnWrapper className="--left" anim={""} animS={"5"} style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-os1dos --full pb--30 pt--30" name={"vc1ltue7x37"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19880/52b0d3a447ac4b788bd1a7c1ed16437d_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19880/52b0d3a447ac4b788bd1a7c1ed16437d_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19880/52b0d3a447ac4b788bd1a7c1ed16437d_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19880/52b0d3a447ac4b788bd1a7c1ed16437d_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19880/52b0d3a447ac4b788bd1a7c1ed16437d_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19880/52b0d3a447ac4b788bd1a7c1ed16437d_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19880/52b0d3a447ac4b788bd1a7c1ed16437d_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19880/52b0d3a447ac4b788bd1a7c1ed16437d_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"sluzby1"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box fs--36" content={"Kdo jsme ?"}>
              </Subtitle>

              <Text className="text-box fs--22" content={"Naše rodinná firma se specializuje na dodávku a montáž lepených vinylových podlah, které jsou oblíbené pro svůj vzhled, dlouhodobou životnost a možnost výměny jednotlivých lamel"}>
              </Text>

              <Subtitle className="subtitle-box fs--36" content={"Co nabízíme ?"}>
              </Subtitle>

              <Text className="text-box ff--2 fs--22" content={"Zabýváme se pokládkou nejrůznějších typů podlah včetně přípravy podkladu&nbsp;"}>
              </Text>

              <Subtitle className="subtitle-box fs--36" content={"Konzultace"}>
              </Subtitle>

              <Text className="text-box ff--2 fs--22" style={{"maxWidth":""}} content={"Našim zákazníkům se snažíme poradit nejlepší možnou variantu realizace s ohledem na jejich finanční a časové možnosti, dlouhodobou udržitelnost a životnost podlah"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Výběr"}>
              </Subtitle>

              <Text className="text-box ff--2 fs--22" style={{"maxWidth":""}} content={"Samozřejmostí je pomoc našim zákazníkům s výběrem typu podlahy, který se nejvíce hodí pro daný prostor a s výběrem motivů ze vzorníků, které máme vždy u sebe"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Kalkulace"}>
              </Subtitle>

              <Text className="text-box ff--2 fs--22" style={{"maxWidth":""}} content={"Po 3D zaměření prostoru přichází na řadu kalkulace a vytvoření cenové nabídky&nbsp;"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Realizace"}>
              </Subtitle>

              <Text className="text-box ff--2 fs--22" content={"Nabízíme demontáž a likvidaci staré podlahové krytiny, měření vlhkosti podkladu, renovaci/vyrovnání podkladu, dodávku a montáž podlahových krytin"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Servis"}>
              </Subtitle>

              <Text className="text-box fs--22" content={"Po dokončení realizace Vám poradíme, jak o daný typ podlahy pečovat.<br>&nbsp;U lepených vinylových podlah a kobercových čtverců nabízíme výměnu jednotlivých lamel v případě jejich poškození"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 pb--60 pt--60" anim={"2"} name={"sluzby"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3 pb--16 pt--06 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" content={"Vinylové podlahy"}>
              </Subtitle>

              <Text className="text-box" content={"Dodávka a montáž vinylových podlah lepených i plovoucích"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" content={"PVC podlahy"}>
              </Subtitle>

              <Text className="text-box" content={"Dodávka a montáž PVC podlahovin objektových i bytových&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" content={"Rigidní podlahy"}>
              </Subtitle>

              <Text className="text-box" content={"Dodávka a montáž rigidních&nbsp;<br>podlah"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" content={"Koberce"}>
              </Subtitle>

              <Text className="text-box" content={"Dodávka a montáž koberců lepených i <br>\"na volno\" položených."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" content={"Kobercové čtverce"}>
              </Subtitle>

              <Text className="text-box" content={"Dodávka a montáž kobercových čtverců"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" content={"Laminátové podlahy"}>
              </Subtitle>

              <Text className="text-box" content={"Dodávka a montáž laminátových a dřevěných zámkových podlah"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"galerie"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Button className="btn-box btn-box--hvr5 btn-box--sbtn2 btn-box--pbtn2 btn-box--tsbtn5 btn-box--cColor1 btn-box--design3 btn-box--center ff--2 fs--48" url={"https://www.facebook.com/profile.php?id=61556019064367"} href={"https://www.facebook.com/profile.php?id=61556019064367"} tsbtn={"5"} content={"<span style=\"color: var(--white);\">Fotogalerie</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"kontakt"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Vladimír Chalupa"}>
              </Subtitle>

              <Text className="text-box fs--22" content={"Do<span style=\"font-style: italic;\">ln</span>ozahradská 1769/17<br>767 01 Kroměříž<br>Česká Republika<br>IČO: 21151920<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Provozní doba"}>
              </Subtitle>

              <Text className="text-box fs--22" content={"Konzultace a poptávky:<br>&nbsp;PO - PÁ 8.00 - 17.00 hod.\n<br>Zaměření a realizace:\n<br>Dle dohody"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Kontakt"}>
              </Subtitle>

              <Text className="text-box fs--22" content={"+420 724 952 477\n<br>+420 606 025 400<br>podlahychalupa@gmail.com<br>Id datové schránky: kft6mn9<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"h9ih2la5cq7"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" content={"<span style=\"color: rgb(8, 8, 40);\">podlahy Chalupa Kroměříž, podlahy Kroměříž, podlahy Kromeriz, podlahářské práce Kroměříž, podlaharske prace Kromeriz, podlahář Kroměříž, podlahar Kromeriz, podlaháři Kroměříž, podlahari Kromeriz, prodej podlah Kroměříž, podlahové studio Kroměříž, podlahy Chalupa Kroměříž, podlahy Chalupa Kromeriz</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}